<template>
  <div class="footer">
    <div class="container flex">
      <div class="footer__doc">
        <a
          v-if="showPdfLink"
          :href="documents.publicOffer"
          target="_blank"
          class="footer__link"
        >
          {{ t("footer.publicOffer") }}
        </a>
        <a
          v-if="showPdfLink"
          :href="documents.termsOfUse"
          target="_blank"
          class="footer__link disabled"
        >
          {{ t("footer.termsOfUse") }}
        </a>

        <a
          v-if="currentSupportLink"
          target="_blank"
          class="footer__link"
          :href="currentSupportLink"
        >
          {{ t("footer.support") }}
        </a>
        <button
          type="button"
          class="footer__link"
          @click="handleModalToggle(true)"
        >
          {{ t("footer.systemRequirements") }}
        </button>
        <a
          v-if="showPdfLink"
          :href="documents.personalDataProcessingRules"
          target="_blank"
          class="footer__link"
        >
          {{ t("footer.personalDataProcessingRules") }}
        </a>
      </div>

      <div class="footer__copyright">
        {{ currentCopyright(t, date) }}
      </div>

      <SystemRequirementsModal
        :isOpen="isModalOpen"
        @close="handleModalToggle($event)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import useDocumentLinks from '@/composition/useDocumentLinks';
import dayjs from '@/utils/dayjs';

import { currentCopyright, currentSupportLink } from '@/utils';

import SystemRequirementsModal from '@/components/SystemRequirmentsModal.vue';

const { t } = useI18n();

const isModalOpen = ref<boolean>(false);

const date = dayjs().format('YYYY');
const documents = useDocumentLinks();

// TODO: show en and fr document links
// Ссылки в футере временно скрыты для международного портала
const showPdfLink = process.env.VUE_APP_TYPE !== 'international';

const handleModalToggle = (flag: boolean) => {
  isModalOpen.value = flag;
};
</script>

<style scoped lang="scss">
@import "@/assets/style/include.scss";

.footer {
  background: $color-white;
}

.flex {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 18px;
  padding-top: 18px;

  @include media-breakpoint-down("sm") {
    flex-wrap: wrap;
    gap: 20px;
  }
}

.footer__doc {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr 1fr 1fr;

  @include media-breakpoint-down("md") {
    grid-template-columns: 1fr;
  }
}

.footer__link {
  color: var(--color-blue-darkest-footer);
  cursor: pointer;
  font-size: 14px;
  font-weight: 300;
  text-align: left;
  text-decoration: underline;

  &.disabled {
    pointer-events: none;
    cursor: pointer;
  }

  @include hover-focus() {
    text-decoration: none;
  }
}
</style>
