<template>
  <component
    :is="isLink ? 'a' : 'button'"
    type="button"
    :class="[
      cssClass,
      `${cssClass}_${appearance}`,
      `${cssClass}_${color}`,
      `${cssClass}_${size}`,
      { [`${cssClass}_rounded`]: rounded },
    ]"
    :href="href"
    :target="target"
  >
    <slot />
  </component>
</template>

<script setup lang="ts">
const cssClass = 'btn';

defineProps({
  appearance: {
    type: String,
    default: 'solid',
    validator: (appearance: string) => ['solid', 'outline', 'control'].includes(appearance),
  },
  color: {
    type: String,
    default: 'primary',
    validator: (color: string) => ['primary', 'secondary', 'danger', 'success'].includes(color),
  },
  size: {
    type: String,
    default: 'medium',
    validator: (size: string) => ['small', 'square', 'medium', 'wide'].includes(size),
  },
  isLink: {
    type: Boolean,
    default: false,
  },
  href: {
    type: String,
    default: undefined,
  },
  target: {
    type: String,
    default: undefined,
  },
  /* TODO NEED TO REMOVE SOMETIMES; TOO MUCH BUTTONS HAS DIFFERENT VIEW */
  rounded: {
    type: Boolean,
    // eslint-disable-next-line vue/no-boolean-default
    default: true,
  },
});
</script>

<style lang="scss">
@import "@/assets/style/variables.scss";

@mixin __btn-color(
  $textColor,
  $mainColor,
  $activeColor,
  $disabledColor,
  $hoverColor
) {
  background-color: $mainColor;
  border-color: $mainColor;
  color: $textColor;

  &:hover,
  &:focus {
    background-color: $hoverColor;
    border-color: $hoverColor;
  }

  &:active {
    background-color: $activeColor;
    border-color: $activeColor;
  }

  &:disabled {
    background-color: $disabledColor;
    border-color: $disabledColor;
    color: $disabledColor;
  }
}

.btn {
  align-items: center;
  border-radius: $btn-border-radius;
  border: $btn-border-width solid transparent;
  box-sizing: $btn-box-sizing;
  cursor: pointer;
  display: inline-flex;
  font: $btn-font-size $btn-font-family;
  height: $btn-height;
  justify-content: center;
  transition: $btn-transition;

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
  }

  // sizes
  &_small {
    min-width: $btn-min-width_small;
    padding: $btn-padding;
  }

  &_square {
    min-width: $btn-height;
    padding: 0;
  }

  &_medium {
    min-width: $btn-min-width;
    padding: $btn-padding;
  }

  &_wide {
    min-width: $btn-min-width;
    padding: $btn-padding_wide;
  }

  // colors
  &_primary {
    @include __btn-color(
      $btn-color-primary,
      $btn-bg-primary,
      $btn-bg-primary_active,
      $color-disabled,
      $btn-bg-primary_hover
    );
  }

  &_rounded {
    border-radius: $btn-border-radius-rounded;
  }

  &_secondary {
    @include __btn-color(
      $btn-color-secondary,
      $btn-bg-secondary,
      $btn-bg-secondary_active,
      $color-disabled,
      $btn-bg-secondary_hover
    );
  }

  &_danger {
    @include __btn-color(
      $btn-color-danger,
      $btn-bg-danger,
      $btn-bg-danger_active,
      $color-disabled,
      $btn-bg-danger_hover
    );
  }

  &_success {
    @include __btn-color(
      $btn-color-white,
      $btn-bg-success,
      $btn-bg-success_active,
      $color-disabled,
      $btn-bg-success_hover
    );
  }

  // appearance
  &_solid {
    &,
    &:disabled {
      color: $btn-color-white;
    }
  }

  &_outline {
    background-color: transparent;

    &:disabled {
      background-color: transparent;
    }

    &:hover:not(:disabled),
    &:focus:not(:disabled) {
      color: $btn-color-white;
    }
  }

  &_control {
    height: $btn-height-control;
    min-width: $btn-min-width-control;
    padding: $btn-padding-control;

    @include __btn-color(
      $btn-color-control,
      $btn-bg-control,
      $btn-bg-control_active,
      $btn-bg-control_disabled,
      $btn-bg-control_hover
    );
  }
}
</style>
